import React from 'react';

export function Loader() {
  return (
    <>
      <div>
        <p> Loading </p>
      </div>
    </>
  );
}